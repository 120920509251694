import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/chat',
  kanban: '/kanban',
  calendar: '/calendar',
  auth: {
    me: '/admin/getTenant',
    login: '/admin/auth/login',
    register: '/auth/register',
    update: '/admin/updateTenantDetails',
    updateCheckin:'/admin/updateCheckin',
  },
  mail: {
    list: '/mail/list',
    details: '/mail/details',
    labels: '/mail/labels',
  },
  post: {
    list: '/post/list',
    details: '/post/details',
    latest: '/post/latest',
    search: '/post/search',
  },
  product: {
    list: '/product/list',
    details: '/product/details',
    search: '/product/search',
  },
  user: {
    list: '/api/user/customer-overview',
    addUpdateUser: '/admin/createfromdashboard',
    addUpdateUserCam: '/admin/createfromdashboardcam',
    profile: '/api/nutritionPlanfull/',
    profileSmall: '/api/nutritionPlan/',
    profileTemp: '/api/nutritionPlanTemp/',
    stackedChart: '/api/body-comp-data/stacked-chart',
    vitamins: '/api/body-comp-data/vitamins',
    bodyComparisonChart: '/api/body-comp-data/bodycomp',
    delete: '/admin/deleteUser',
    update: '/admin/updateUser',
    checkEmail: '/admin/checkUser',
    updatePermission: '/admin/updateUserPermission',
    updateNotes: '/admin/update-notes',
    updateSharedNotes: '/admin/update-shared-notes',
    updatePassword:'/api/newPasswordDashboard',
    sendFeedbacckResponse: '/admin/send-feedback-response',
    updateAutoRenew: '/api/user/auto-renew',
    unlockUser:'/api/user/unlockuser',
    updateEndDate: '/admin/update-end-date',
    referEarn:'admin/refer-earn',
    register:'/admin/auth/register',
    checkout:'/admin/checkout',
    checkoutWP:'/admin/checkoutwp',
    getPermissions:'admin/getPermissions' ,
    thankyou:'admin/payment-recived',
    unlockUserApp:'api/user/unlockuserapp',
    autorenewnow:'api/user/renewSingle',
  },
  code: {
    list: '/api/user/user-code-overview',
    createCode: '/admin/ceatetenantcoupon?token=s6dfYvuypfb77W3A7KbOspF',
  },
  ingredient:{
    list: '/admin/searchIngredientDashboard',
  },
  recipe: {
    // list: '/api/user/user-recipe-overview',
    list: '/api/shopping/recipes',
    searchIngredients: '/admin/search-ingredients',
    searchIngredientsTracking: '/admin/search-ingredients-tracking',
    searchDashboard: '/admin/searchDashboard',
    createRecipe: '/admin/recipes/create?token=s6dfYvuypfb77W3A7KbOspF',
    addIngredientInRecipe: (recipeId: number) => `/admin/recipe/ingredient/${recipeId}?token=s6dfYvuypfb77W3A7KbOspF`,
    storeRecipe: (recipeId: number) => `/admin/recipe/store/${recipeId}?token=s6dfYvuypfb77W3A7KbOspF`,
    removeIngredient: (recipeId: number, ingredientId: number) => `/admin/recipe/ingredient/${recipeId}/${ingredientId}/remove?token=s6dfYvuypfb77W3A7KbOspF`,
    editIngredient: (recipeId: number, ingredientId: number,ammount:number) => `/admin/recipe/ingredient/${recipeId}/${ingredientId}/edit/${ammount}?token=s6dfYvuypfb77W3A7KbOspF`,
    deleteRecipe: (recipeId: number) => `/admin/recipetrainer/${recipeId}?token=s6dfYvuypfb77W3A7KbOspF`,
    addNewIngredient: `/admin/adding?token=s6dfYvuypfb77W3A7KbOspF`,
    deleteIngredient: `/admin/deleteing?token=s6dfYvuypfb77W3A7KbOspF`,
    removeFavorite: (recipeId: number,tenant_id:number) => `/api/removefavoriteTenant/${recipeId}/${tenant_id}`,
    addFavorite: (recipeId: number,tenant_id:number) => `/api/favoriteTenant/${recipeId}/${tenant_id}`,
    copyRecipe: (recipeId: number,tenant_id:number) => `/api/copyRecipe/${recipeId}/${tenant_id}`,
  },
  documents:{
    upload: '/api/docs/tenant-documents-overview',
    list: (tenant_id: number)=> `/api/docs/get-documents/${tenant_id}`,
  }
};
