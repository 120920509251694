import { useCallback, useEffect } from 'react';
import { m } from 'framer-motion';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const locales = useLocales();
    const { t } = useLocales();

    const popover = usePopover();

    const handleChangeLang = useCallback(
        (newLang: string) => {
            locales.onChangeLang(newLang);
            popover.onClose();
        },
        [locales, popover]
    );

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const lang = params.get('lang');
        if (lang) {
            handleChangeLang(lang);
            params.delete('lang');
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState({}, '', newUrl);
        }
    }, [handleChangeLang]);

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(popover.open && {
                        bgcolor: 'action.selected',
                    }),
                }}
            >
                <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
                {locales.allLangs.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === locales.currentLang.value}
                        onClick={() => handleChangeLang(option.value)}
                    >
                        <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />
                        {t(option.label)}
                    </MenuItem>
                ))}
            </CustomPopover>
        </>
    );
}